@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  font-family: "Inter", sans-serif !important;
}

*,
*:before,
*:after {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.flow-hide {
  overflow-y: scroll;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
}
.flow-hide-x-1 {
  overflow-x: auto;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
}

.flow-hide-x {
  overflow-x: scroll;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
}
.flow-hide-x::-webkit-scrollbar {
  width: 0;
  height: 0;
  cursor: pointer;
}
.flow-hide::-webkit-scrollbar {
  /* WebKit */
  width: 0;
  height: 0;
}
.hide-scroll {
  scrollbar-width: none !important; /* Firefox */
  -ms-overflow-style: none !important; /* Internet Explorer 10+ */
}
.hide-scroll::-webkit-scrollbar {
  width: 0 !important;
  height: 0 !important;
}
.active-t-btn {
  border-radius: 6.93px;
  border: 0.5px solid rgba(0, 0, 0, 0.04);
  background: #fff;
  box-shadow:
    0px 3px 1px 0px rgba(0, 0, 0, 0.04),
    0px 3px 8px 0px rgba(0, 0, 0, 0.12);
}

.tounament-header-bg {
  background-color: rgba(118, 118, 128, 0.12);
  width: 100%;
}
.not-box {
  box-shadow: 0px 9px 30px rgba(0, 0, 0, 0.07);
  border-radius: 14px;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.btn-drop {
  box-shadow: 0px 33px 56px -19px rgba(174, 255, 0, 0.1);
}

.shade {
  box-shadow: 0px -9px 30px rgba(0, 0, 0, 0.07);
}

.home-hero-bg {
  background: url(./assets/png/home-bg.png);
  width: 100%;

  background-repeat: no-repeat;
  background-size: cover;
  min-height: 160px;
}
.profile-hero-bg {
  background: url(./assets/png/profile-mask.png);
  width: 100%;

  background-repeat: no-repeat;
  background-size: cover;
}

.home-option {
  box-shadow: 0px 9px 30px rgba(0, 0, 0, 0.07);
  border-radius: 24px;
  height: 150px;
  width: 100%;
}

.z-1 {
  z-index: 1;
}

.z-5 {
  z-index: 5 !important;
}
.z-high {
  z-index: 120 !important;
}
.modal-high {
  z-index: 1000 !important;
}
.nav-shadow {
  box-shadow: 0px -3px 64px 0px #0000001a;
}
.more-shadow {
  background: #ffffff;
  box-shadow: 0px 9px 30px rgba(0, 0, 0, 0.07);
  border-radius: 14px;
  width: 40px;
  height: 40px;
  display: grid;
  place-items: center;
}

.image-shad {
  filter: drop-shadow(0px 30px 60px rgba(0, 0, 0, 0.05));
  border-radius: 18px;
}
.imag-shad-big {
  /* background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(.jpg); */
  box-shadow: 0px 30px 60px rgba(0, 0, 0, 0.05);
  border-radius: 30px;
}

.court-p-shad {
  background: rgba(255, 255, 255, 0.43);
  border-radius: 12px;
}
.auth-stage-one {
  background: url("./assets/png/auth1.png");
  height: 100%;
  min-height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.auth-stage-two {
  background: url("./assets/png/auth2.png");
  height: 100%;
  min-height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.auth-stage-three {
  background: url("./assets/png/auth3.png");
  height: 100%;
  min-height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.glass-progress {
  background: linear-gradient(
    143.66deg,
    rgba(255, 255, 255, 0.41) -32.17%,
    rgba(0, 140, 183, 0.11) 97.91%,
    rgba(0, 140, 183, 0.009375) 97.92%,
    rgba(255, 255, 255, 0) 97.93%,
    rgba(255, 255, 255, 0.2) 97.93%
  );
  filter: drop-shadow(0px 33.3983px 20px rgba(95, 102, 116, 0.29));
  backdrop-filter: blur(20px);
  /* Note: backdrop-filter has minimal browser support */

  border-radius: 15px;
}

.small-input {
  border: 1px solid #5e5e5e !important;
  outline: none;
}

.small-input:focus {
  border: 1px solid #4cb963 !important;
}

.tennis-bg {
  background-image: url("./assets/svgs/tennis_bg.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;
}

.calendar-icon {
  position: absolute;
  top: 50%;
  right: 8px;
  transform: translateY(-50%);
  cursor: pointer;
}

.date-enforce {
  opacity: 0 !important;
}

.invite-shadow {
  box-shadow: 0px 4px 22px 0px #90909040;
}

.link-card-shadow {
  box-shadow: 0px -1px 23.7px 0px #e2e6e833;
  border: 0.5px solid #f4f4f4;
  background-color: #fff;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none !important;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield; /* Firefox */
}

.rc-shadow {
  box-shadow: 0px 30px 60px 0px #0000000d;
}

.new-home-banner {
  background: url("./assets//new-png/mob-cover.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 160px;
  min-height: 160px;
  width: 100%;
}

.competition-card-shadow {
  box-shadow: 0px -1px 12px 0px #0000001a;
}

.tab-shadow {
  box-shadow: 0px 4px 30px 0px #b5b5b58a;
}
.fixture-card-shadow {
  box-shadow: 0px -1px 24px 0px #0000001a;
}
